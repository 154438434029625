
// 変数
// ---------------------------------------------
$breakpoint-sp: 580px;
//border-bottom: 1px solid $mainColor08;

// grid configs
$var-breakpoint:				580px;
$var-col:						12;
$var-max-width:					100%;
$var-col-gutters:				4%;
$var-col-width:					$var-max-width / $var-col;
$var-col-width-gutters:			($var-max-width + $var-col-gutters) / $var-col;

// width設定
$topMainWidth: 1160px;
$mainWidthWide: 1330px;
$mainWidth: 850px;
$sideWidth: 260px;
$gNavHight: 40px;

// メインカラー設定
$baseColor: #343434;
$linkColor: #1992c1;

$mainColor01: #084197; //例） メニューバーの青/arrowアイコン系で使用
$mainColor02: #1992c1; //例） pageHeaderのサブタイトル色/table表の見出し背景色
$mainColor03: #014099; //例） headLine1のサブタイトル・罫線色/ボタンカラー
$mainColor04: #2493BF; //例） headLine2/サイドメニュー見出しの背景色
$mainColor05: #E6F3F8; //例） ドロップダウンメニューの背景色/サイドメニューのアイコーディオンボタン背景色
$mainColor06: #F3F3F3; //例） サイドメニューナビゲーションリンクの背景色
$mainColor07: #F0F0F0; //例） table表の見出し背景色2
$mainColor08: #E5E5E5; //例） 罫線関連
$mainColor09: #C6C6C6; //例） ドット罫線
$mainColor10: #009e96; //
$mainColor11: #324a89; //

$lightenColor01: lighten($mainColor10, 25%); //
$lightenColor02: lighten($mainColor01, 60%); //

// gradient color
// @include gradientColor01;
@mixin gradientColor01 {
	background: rgb(0,140,200);
	background: linear-gradient(90deg, rgba(0,140,200,1) 0%, rgba(32,187,247,1) 100%);
}
@mixin gradientColor02 {
	background: rgb(0,168,158);
	background: linear-gradient(90deg, rgba(0,168,158,1) 0%, rgba(32,215,205,1) 100%);
}

$white: #ffffff;
$black: #000000;

$red: #f43838;
$red-dark: #D01414;

$yellow: #ff0;
$yellow-light: lighten($yellow, 30%);

$gray: #808080; // ok
$gray-light: #c9c9c9; // ok
$gray-exLight: #dbdbdb; // ok
$gray-dark: #565656; // ok
$gray-exDark: #2d2d2d; // ok
$gray-border: #bdbdbd; // ok
$gray-dot: #e6e6e6; // ok
$gray-bg: #f6f6f6; // ok


$box-shadow:1px 1px 1px rgba(0,0,0,0.25);
$input-shadow:1px 1px 1px rgba(0,0,0,0.2) inset;


// font
// -------------------------------------------------------------
// --- font-size 関連 --------------------
// px/rem
// @mixin fs($fs) {
//	$rem: $fs / 10;
//	font-size: #{$fs}px;
//	font-size: #{$rem}rem;
// }

// px/rem
// font-sizeを14pxにしたい場合 : fts(14)
// line-heightを故意的に変えたい場合 : fts(14,28)
@mixin fts($fts, $lin: 24) {
	$rem: $fts / 10;
	$linS: $lin / $fts;
	font-size: #{$fts}px;
	font-size: #{$rem}rem;
	line-height: $linS;
}

// --- FontAwesome --------------------
@mixin fa($code) {
	content: $code;
	font-family: FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// --- WebFont --------------------
// WebFont系
@mixin ff-Montserrat {
	font-family: 'Montserrat', sans-serif;
}
// ゴシック系
@mixin ff-Gothic {
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro',Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
}
// 明朝系
@mixin ff-Mincho {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	// font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

// --- 幅・高さのpx → %変換 --------------------
// ex.) @include widtnP(960, 300px);
@mixin widtnP( $parentPxWidth, $pxWidth ){
	width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}
@mixin minWidtnP( $parentPxWidth, $pxWidth ){
	width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}
@mixin maxWidtnP( $parentPxWidth, $pxWidth ){
	width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}
@mixin heightP($parentPxHeight, $pxHeight){
	height: floor((($pxHeight / $parentPxHeight) * 10000%)) / 100;
}


// mixin
// -------------------------------------------------------------
// --- clearfix --------------------
@mixin cf {
	&::before,
	&::after {
		content: ".";
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
		height: 0;
		line-height: 0;
	}
	&::after {
		content: "";
		clear: both;
	}
}
// --- テキスト非表示 --------------------
@mixin textHidden {
	text-indent: 110%;
	white-space: nowrap;
	overflow: hidden;
	font-size: 0;
	text-indent: -9999px;
	// text-indent: -9999px;
	// display: none;
	// white-space: nowrap;
	// overflow: hidden;
	// display: none\9;
}
// --- 三点リーダー --------------------
@mixin textEndLeader {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

// --- 単語の強制折り返し --------------------
@mixin wordRap {
	word-wrap: break-word;
	white-space: normal;
	word-break: break-all;
}
// --- 単語の強制折り返し禁止 --------------------
@mixin wordNorap {
	white-space: nowrap;
}
// --- リンクHover時の下線設定 --------------------
@mixin hoverLine {
	text-decoration: underline;
}

// --- display : inline_block 関連 --------------------
// 親設定
@mixin inline_block-p {
	letter-spacing: -.44em;
}
// 子設定
@mixin inline_block {
	display: inline-block;
	letter-spacing: normal;
	vertical-align: middle;
	// font-size: 100%;
	// *display: inline;
	// *zoom: 1;
}

// --- display : table 関連 --------------------
// table 設定
@mixin display_table {
	display: table;
	table-layout: fixed;
	width: 100%;
}
// table-cell 設定
@mixin display_table-cell {
	display: table-cell;
	vertical-align: top;
}

// --- overlay --------------------
@mixin overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: default;
	// background-color: rgba(0,0,0,.6);
	// background-color: rgba(255,255,255,.8);
	background-color: transparent;
	opacity: 0;
	-webkit-transition: all .5s;
	   -moz-transition: all .5s;
	    -ms-transition: all .5s;
	     -o-transition: all .5s;
	        transition: all .5s;
	z-index: 2000;
	display: none;
}

// ------ scrollbar 関連 ------
@mixin scrollbar {
	min-width: initial;
	// overflow-x: hidden;
	&::-webkit-scrollbar {
		cursor: pointer;
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		background: #F8F8F8;
		cursor: pointer;
	}
	&::-webkit-scrollbar-thumb {
		border-image-source: initial;
		border-image-slice: initial;
		border-image-width: initial;
		border-image-outset: initial;
		border-image-repeat: initial;
		z-index: 9999;
		background-clip: content-box;
		width: 5px;
		background-color: rgba(0, 0, 0, 0.8);
		border-width: 2px;
		border-style: solid;
		border-color: transparent;
		border-radius: 12px;
		margin: 1px !important;
	}
}

// ------ SPサイト時共通padding設定 ------
@mixin padLR-A {
	padding: 0 5px;
}
@mixin padLR-B {
	padding: 0 10px;
}
