@charset "UTF-8";
@import '_config.scss';
/**********************************************
日信化学工業株式会社
TOP CSS
***********************************************/


/*============================================================
  body
============================================================*/
.contents {
}
.pageID-top {
}


/*============================================================
  contents
============================================================*/
.contents {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*============================================================
  mainVisual - メインビジュアル
============================================================*/
.mainVisual {
	$this: &;
	position: relative;
	margin: 0 auto;
	width: $mainWidthWide;
	min-height: 440px;
	&_list {
	}
	&_item {
		&-only {
			position: relative;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.mainVisual {
		width: 100%;
		min-height: 210px;
		&_list {
		}
		&_item {
			img {
				width: 100%;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}

/*============================================================
  bnrMenu - バナーメニュー
============================================================*/
.bnrMenu {
	$this: &;
	$space: 35px;
	position: relative;
	&_inner {
		margin: 30px auto 0;
		width: $topMainWidth;
		text-align: center;
	}
	&_list {
		@include inline_block-p;
		margin: -#{$space} 0 0 -#{$space};
	}
	&_item {
		@include inline_block;
		vertical-align: top;
		margin: #{$space} 0 0 #{$space};
		a {
			display: block;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.bnrMenu {
		$space: 22px;
		&_inner {
			width: 100%;
			padding: 0 12px;
		}
		&_list {
			margin: 0;
		}
		&_item {
			display: block;
			margin: #{$space} 0 0;
			&:first-child {
				margin-top: 0;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

/*============================================================
  mainContents - メインコンテンツエリア
============================================================*/
.mainContents {
	$this: &;
	position: relative;
	margin: 0 auto;
}
@media screen and (max-width: $breakpoint-sp) {
	.mainContents {
		width: 100%;
	}
}

/*============================================================
  search - カテゴリー検索(タブエリア)
============================================================*/
.search {
	$this: &;
	position: relative;
	margin-top: 63px;
	&_inner {
		position: relative;
		margin: 0 auto;
		width: 1300px;
	}
	&_nav {
		padding: 0 70px;
		height: 43px;
		background: url('./../images/shared/bg_line.png') left bottom no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	&_list {
		@include inline_block-p;
		margin-top: 0;
	}
	&_item {
		@include inline_block;
		margin-left: -1px;
		width: 253px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: $mainColor03;
		font-size: 16px;
		border: 1px solid $mainColor08;
		border-bottom: none;
		cursor: pointer;
		&:first-child {
			margin-left: 0;
		}
	}
	&_body {
		background: rgb(255,255,255);
		background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(230,243,248,1) 10%, rgba(230,243,248,1) 52%, rgba(230,243,248,1) 90%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(230,243,248,1) 10%,rgba(230,243,248,1) 52%,rgba(230,243,248,1) 90%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(230,243,248,1) 10%,rgba(230,243,248,1) 52%,rgba(230,243,248,1) 90%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );

	}
	&_title {
		text-align: center;
		color: $mainColor03;
		font-size: 28px;
		font-weight: normal;
		line-height: 1;
	}
	.navhit {
		border: none;
		color: #fff;
		background-color: $mainColor04;
	}
}
.searchBox {
	$this: &;
	min-height: 570px;
	padding: 35px 0 40px;
}
.searchCategory {
	$this: &;
	margin: 0 auto;
	width: $topMainWidth;
	&_inner {
		margin-top: 35px;
	}
	&_list {
		@include inline_block-p;
		margin: 0;
	}
	&_item {
		@include inline_block;
		a {
			display: block;
			padding: 7px 9px 17px;
			height: 100%;
			color: inherit;
			text-decoration: none;
			background-color: #fff;
		}
	}
	&_img {
		position: relative;
		margin: 0;
		min-width: 210px;
		height: 130px;
		overflow: hidden;
		text-align: center;
		// background-color: $mainColor05;
		// border: 1px solid $mainColor08;
		> img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&_title {
		margin-top: 12px;
		color: $mainColor03;
		font-size: 15px;
		font-weight: normal;
	}
	&_txt {
		margin-top: 10px;
		font-size: 12px;
	}
	&.col4 {
		$space: 25px;
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 4 ) / 4);
			width: calc( ( 100% - 4px - #{$space} * 4 ) / 4)\9;
		}
	}
	&.col5 {
		$space: 6px;
		#{$this}_list {
			margin: -25px 0 0 -#{$space};
		}
		#{$this}_item {
			margin: 25px 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 5 ) / 5);
			width: calc( ( 100% - 5px - #{$space} * 5 ) / 5)\9;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.search {
		$this: &;
		margin-top: 33px;
		&_inner {
			width: 100%;
		}
		&_nav {
			display: none;
		}
		&_body {
			background: none;
		}
		&_title {
			color: #222222;
			font-size: 19px;
			font-weight: bold;
		}
	}
	.searchBox {
		$this: &;
		margin: 0;
		padding: 0;
		min-height: 0;
		// 各カテゴリ毎のデザイン設定
		&-product ,
		&-use ,
		&-feature {
			.searchCategory {
				&_inner {
					margin-top: 16px;
				}
				&_item {
					a {
						padding: 13px 10px 10px;
					}
				}
				&_title {
					font-size: 14px;
					padding-left: 16px;
					&:before {
						position: absolute;
						left: 0;
						content:"\f138";
						font-family: FontAwesome;
						color: 13px;
						font-size: inherit;
					}
				}
			}
		}
		&-product {
			padding: 18px 6px;
			background: url('./../images/shared/bg_company.jpg') left top no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
		&-use {
			padding: 22px 6px 25px;
			background: #fff;
			.searchCategory {
				&_inner {
					margin-top: 10px;
				}
				&_item {
					a {
						padding: 5px;
						border: 1px solid $mainColor08;
					}
				}
				&_img {
					display: block;
				}
				&_title {
					margin-top: 10px;
				}
			}
		}
		// &-feature {
		//	display: none;
		// }
	}
	.searchCategory {
		$this: &;
		width: 100%;
		&_inner {
			margin-top: 0;
		}
		&_list {
			padding: 0;
		}
		&_item {
			padding: 0;
			a {
				display: block;
				margin: 0;
				background-color: rgba(255, 255, 255, .8);
			}
		}
		&_img {
			min-width: 0;
			height: 80px;
			> img {
				position: relative;
				top: auto;
				right: auto;
				bottom: auto;
				left: auto;
			}
		}
		&_txt {
			display: none;
		}
		&_title {
			position: relative;
			margin-top: 5px;
			color: $mainColor03;
			font-size: 13px;
		}
		&.col4 ,
		&.col5 {
			$space: 5px;
			#{$this}_list {
				margin: -#{$space} 0 0 -#{$space};
			}
			#{$this}_item {
				margin: #{$space} 0 0 #{$space};
				width: calc( ( 100% - #{$space} * 2 ) / 2);
				width: calc( ( 100% - 2px - #{$space} * 2 ) / 2)\9;
			}
		}
	}
}


/*============================================================
  guide - 会社案内
============================================================*/
.guide {
	margin-top: 66px;
	&_inner {
		position: relative;
		margin: 0 auto;
		padding: 35px 0 40px;
		width: 1300px;
		height: 470px;
		background: url('./../images/shared/bg_company.jpg') left top no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	&_title {
		text-align: center;
		color: #333;
		font-size: 30px;
		font-weight: normal;
	}
	&Nav {
		@include inline_block-p;
		margin: 0 auto;
		padding: 31px;
		width: $topMainWidth;
		background-color: rgba(255, 255, 255, .5);
		&_list {
			margin: -57px 0 0 -57px;
			text-align: center;
		}
		&_item {
			@include inline_block;
			margin: 57px 0 0 57px;
			width: 173px;
			text-align: center;
			a {
				display: block;
				color: inherit;
				text-decoration: none;
			}
		}
		&_img {
			margin-top: 0;
			text-align: center;
		}
		&_txt {
			margin-top: 12px;
			color: #333;
			font-size: 15px;
		}
	}
	&_link {
		margin-top: 34px;
		text-align: center;
		.btn {
			padding: 13px 30px;
			width: 380px;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.guide {
		margin-top: 0;
		&_inner {
			padding: 20px 0 23px;
			width: 100%;
			height: auto;
			background: url('./../images/shared/bg_company.jpg') left top no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
		&_title {
			font-size: 19px;
			font-weight: bold;
		}
		&Nav {
			margin-top: 20px;
			padding: 0 0 20px;
			width: 100%;
			&_list {
				margin: 0;
			}
			&_item {
				margin: 20px 0 0 0;
				width: 50%;
				padding: 0 18px;
				a {
					margin: 0 auto;
					width: 100%;
				}
				&:nth-child(odd) {
					padding-right: 8px;
				}
				&:nth-child(even) {
					padding-left: 8px;
				}
			}
			&_img {
				img {
					width: 100%;
					height: auto;
				}
			}
			&_txt {
				margin-top: 9px;
				font-size: 12px;
			}
		}
		&_link {
			margin-top: 23px;
			.btn {
				width: 252px;
			}
		}
	}
}

/*============================================================
  news - 新着情報
  css設定はmodule.scssにて設定
============================================================*/
.news {
	margin-top: 52px;
	&_inner {
		margin: 0 auto;
		width: 1300px;
	}
	&_list {
		width: $topMainWidth;
	}
	&_link {
		width: $topMainWidth;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.news {
		margin-top: 26px;
		&_inner {
			width: auto;
		}
		&_list {
			width: auto;
		}
		&_link {
			width: auto;
		}
	}
}

/*============================================================
  TOPスライダー
----------------------------------
  sliderPro
============================================================*/
.sp-arrows {
	.sp-arrow {
		width: 33px;
		height: 33px;
		background: rgba(76, 76, 76, .8);
	}
	.sp-next-arrow:after, .sp-next-arrow:before, .sp-previous-arrow:after, .sp-previous-arrow:before {
		top: 3px;
		display: inline-block;
		-webkit-transform: skew(0deg, 0deg);
		-ms-transform: skew(0deg, 0deg);
		transform: skew(0deg, 0deg);
		width: 0;
		height: 0;
		line-height: 1;
		font-size: 26px;
		color: #fff;
	}
	.sp-previous-arrow:before {
		content:"\f104";
		font-family: FontAwesome;
		left: 50%;
		margin-left: -5px;
	}
	.sp-next-arrow:before {
		content:"\f105";
		font-family: FontAwesome;
		right: 50%;
		margin-right: 3px;
	}

}
@media screen and (max-width: $breakpoint-sp) {
}


// /*============================================================
//   TOPスライダー
// ----------------------------------
//   bxslider
// ============================================================*/
// .bx-wrapper {
// 	.bx-viewport {
// 		box-shadow: none;
// 		border: none;
// 		left: 0;
// 		background: transparent;
// 	}
// 	.bx-controls-direction {
// 		.bx-prev,
// 		.bx-next {
// 			position: absolute;
// 			top: 48%;
// 			left: 0;
// 			width: 33px;
// 			height: 33px;
// 			margin: -20px 0 0 0;
// 			padding: 0;
// 			line-height: 33px;
// 			border: none;
// 			border-radius: 0;
// 			opacity: 1;
// 			color: #fff !important;
// 			text-align: center;
// 			font-size: 18px;
// 			font-weight: bold;
// 			font-family: serif;
// 			text-decoration: none;
// 			background: none;
// 			background: rgba(76, 76, 76, .8);
// 			text-indent: 0px !important;
// 			z-index: 1 !important;
// 		}
// 		.bx-next {
// 			left: auto;
// 			right: 0;
// 		}
// 	}
// }
// @media screen and (max-width: $breakpoint-sp) {
// 	.bx-wrapper {
// 		.bx-controls-direction {
// 			.bx-prev,
// 			.bx-next {
// 				width: 28px;
// 				height: 28px;
// 				line-height: 28px;
// 			}
// 			.bx-prev {
// 				left: 5px;
// 			}
// 			.bx-next {
// 				right: 5px;
// 			}
// 		}
// 	}
// }
