@charset "UTF-8";
/**********************************************
日信化学工業株式会社
TOP CSS
***********************************************/
/*============================================================
  body
============================================================*/
/*============================================================
  contents
============================================================*/
/*============================================================
  mainVisual - メインビジュアル
============================================================*/
.mainVisual {
  position: relative;
  margin: 0 auto;
  width: 1330px;
  min-height: 440px; }
  .mainVisual_item-only {
    position: relative; }

@media screen and (max-width: 580px) {
  .mainVisual {
    width: 100%;
    min-height: 210px; }
    .mainVisual_item img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%; } }

/*============================================================
  bnrMenu - バナーメニュー
============================================================*/
.bnrMenu {
  position: relative; }
  .bnrMenu_inner {
    margin: 30px auto 0;
    width: 1160px;
    text-align: center; }
  .bnrMenu_list {
    letter-spacing: -.44em;
    margin: -35px 0 0 -35px; }
  .bnrMenu_item {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: middle;
    vertical-align: top;
    margin: 35px 0 0 35px; }
    .bnrMenu_item a {
      display: block; }

@media screen and (max-width: 580px) {
  .bnrMenu_inner {
    width: 100%;
    padding: 0 12px; }
  .bnrMenu_list {
    margin: 0; }
  .bnrMenu_item {
    display: block;
    margin: 22px 0 0; }
    .bnrMenu_item:first-child {
      margin-top: 0; }
    .bnrMenu_item img {
      width: 100%;
      height: auto; } }

/*============================================================
  mainContents - メインコンテンツエリア
============================================================*/
.mainContents {
  position: relative;
  margin: 0 auto; }

@media screen and (max-width: 580px) {
  .mainContents {
    width: 100%; } }

/*============================================================
  search - カテゴリー検索(タブエリア)
============================================================*/
.search {
  position: relative;
  margin-top: 63px; }
  .search_inner {
    position: relative;
    margin: 0 auto;
    width: 1300px; }
  .search_nav {
    padding: 0 70px;
    height: 43px;
    background: url("./../images/shared/bg_line.png") left bottom no-repeat;
    -webkit-background-size: contain;
    background-size: contain; }
  .search_list {
    letter-spacing: -.44em;
    margin-top: 0; }
  .search_item {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: middle;
    margin-left: -1px;
    width: 253px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #014099;
    font-size: 16px;
    border: 1px solid #E5E5E5;
    border-bottom: none;
    cursor: pointer; }
    .search_item:first-child {
      margin-left: 0; }
  .search_body {
    background: white;
    background: -moz-linear-gradient(left, white 0%, #e6f3f8 10%, #e6f3f8 52%, #e6f3f8 90%, white 100%);
    background: -webkit-linear-gradient(left, white 0%, #e6f3f8 10%, #e6f3f8 52%, #e6f3f8 90%, white 100%);
    background: linear-gradient(to right, white 0%, #e6f3f8 10%, #e6f3f8 52%, #e6f3f8 90%, white 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); }
  .search_title {
    text-align: center;
    color: #014099;
    font-size: 28px;
    font-weight: normal;
    line-height: 1; }
  .search .navhit {
    border: none;
    color: #fff;
    background-color: #2493BF; }

.searchBox {
  min-height: 570px;
  padding: 35px 0 40px; }

.searchCategory {
  margin: 0 auto;
  width: 1160px; }
  .searchCategory_inner {
    margin-top: 35px; }
  .searchCategory_list {
    letter-spacing: -.44em;
    margin: 0; }
  .searchCategory_item {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: middle; }
    .searchCategory_item a {
      display: block;
      padding: 7px 9px 17px;
      height: 100%;
      color: inherit;
      text-decoration: none;
      background-color: #fff; }
  .searchCategory_img {
    position: relative;
    margin: 0;
    min-width: 210px;
    height: 130px;
    overflow: hidden;
    text-align: center; }
    .searchCategory_img > img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto; }
  .searchCategory_title {
    margin-top: 12px;
    color: #014099;
    font-size: 15px;
    font-weight: normal; }
  .searchCategory_txt {
    margin-top: 10px;
    font-size: 12px; }
  .searchCategory.col4 .searchCategory_list {
    margin: -25px 0 0 -25px; }
  .searchCategory.col4 .searchCategory_item {
    margin: 25px 0 0 25px;
    width: calc( ( 100% - 25px * 4 ) / 4);
    width: calc( ( 100% - 4px - 25px * 4 ) / 4) \9; }
  .searchCategory.col5 .searchCategory_list {
    margin: -25px 0 0 -6px; }
  .searchCategory.col5 .searchCategory_item {
    margin: 25px 0 0 6px;
    width: calc( ( 100% - 6px * 5 ) / 5);
    width: calc( ( 100% - 5px - 6px * 5 ) / 5) \9; }

@media screen and (max-width: 580px) {
  .search {
    margin-top: 33px; }
    .search_inner {
      width: 100%; }
    .search_nav {
      display: none; }
    .search_body {
      background: none; }
    .search_title {
      color: #222222;
      font-size: 19px;
      font-weight: bold; }
  .searchBox {
    margin: 0;
    padding: 0;
    min-height: 0; }
    .searchBox-product .searchCategory_inner, .searchBox-use .searchCategory_inner, .searchBox-feature .searchCategory_inner {
      margin-top: 16px; }
    .searchBox-product .searchCategory_item a, .searchBox-use .searchCategory_item a, .searchBox-feature .searchCategory_item a {
      padding: 13px 10px 10px; }
    .searchBox-product .searchCategory_title, .searchBox-use .searchCategory_title, .searchBox-feature .searchCategory_title {
      font-size: 14px;
      padding-left: 16px; }
      .searchBox-product .searchCategory_title:before, .searchBox-use .searchCategory_title:before, .searchBox-feature .searchCategory_title:before {
        position: absolute;
        left: 0;
        content: "\f138";
        font-family: FontAwesome;
        color: 13px;
        font-size: inherit; }
    .searchBox-product {
      padding: 18px 6px;
      background: url("./../images/shared/bg_company.jpg") left top no-repeat;
      -webkit-background-size: cover;
      background-size: cover; }
    .searchBox-use {
      padding: 22px 6px 25px;
      background: #fff; }
      .searchBox-use .searchCategory_inner {
        margin-top: 10px; }
      .searchBox-use .searchCategory_item a {
        padding: 5px;
        border: 1px solid #E5E5E5; }
      .searchBox-use .searchCategory_img {
        display: block; }
      .searchBox-use .searchCategory_title {
        margin-top: 10px; }
  .searchCategory {
    width: 100%; }
    .searchCategory_inner {
      margin-top: 0; }
    .searchCategory_list {
      padding: 0; }
    .searchCategory_item {
      padding: 0; }
      .searchCategory_item a {
        display: block;
        margin: 0;
        background-color: rgba(255, 255, 255, 0.8); }
    .searchCategory_img {
      min-width: 0;
      height: 80px; }
      .searchCategory_img > img {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto; }
    .searchCategory_txt {
      display: none; }
    .searchCategory_title {
      position: relative;
      margin-top: 5px;
      color: #014099;
      font-size: 13px; }
    .searchCategory.col4 .searchCategory_list, .searchCategory.col5 .searchCategory_list {
      margin: -5px 0 0 -5px; }
    .searchCategory.col4 .searchCategory_item, .searchCategory.col5 .searchCategory_item {
      margin: 5px 0 0 5px;
      width: calc( ( 100% - 5px * 2 ) / 2);
      width: calc( ( 100% - 2px - 5px * 2 ) / 2) \9; } }

/*============================================================
  guide - 会社案内
============================================================*/
.guide {
  margin-top: 66px; }
  .guide_inner {
    position: relative;
    margin: 0 auto;
    padding: 35px 0 40px;
    width: 1300px;
    height: 470px;
    background: url("./../images/shared/bg_company.jpg") left top no-repeat;
    -webkit-background-size: contain;
    background-size: contain; }
  .guide_title {
    text-align: center;
    color: #333;
    font-size: 30px;
    font-weight: normal; }
  .guideNav {
    letter-spacing: -.44em;
    margin: 0 auto;
    padding: 31px;
    width: 1160px;
    background-color: rgba(255, 255, 255, 0.5); }
    .guideNav_list {
      margin: -57px 0 0 -57px;
      text-align: center; }
    .guideNav_item {
      display: inline-block;
      letter-spacing: normal;
      vertical-align: middle;
      margin: 57px 0 0 57px;
      width: 173px;
      text-align: center; }
      .guideNav_item a {
        display: block;
        color: inherit;
        text-decoration: none; }
    .guideNav_img {
      margin-top: 0;
      text-align: center; }
    .guideNav_txt {
      margin-top: 12px;
      color: #333;
      font-size: 15px; }
  .guide_link {
    margin-top: 34px;
    text-align: center; }
    .guide_link .btn {
      padding: 13px 30px;
      width: 380px; }

@media screen and (max-width: 580px) {
  .guide {
    margin-top: 0; }
    .guide_inner {
      padding: 20px 0 23px;
      width: 100%;
      height: auto;
      background: url("./../images/shared/bg_company.jpg") left top no-repeat;
      -webkit-background-size: cover;
      background-size: cover; }
    .guide_title {
      font-size: 19px;
      font-weight: bold; }
    .guideNav {
      margin-top: 20px;
      padding: 0 0 20px;
      width: 100%; }
      .guideNav_list {
        margin: 0; }
      .guideNav_item {
        margin: 20px 0 0 0;
        width: 50%;
        padding: 0 18px; }
        .guideNav_item a {
          margin: 0 auto;
          width: 100%; }
        .guideNav_item:nth-child(odd) {
          padding-right: 8px; }
        .guideNav_item:nth-child(even) {
          padding-left: 8px; }
      .guideNav_img img {
        width: 100%;
        height: auto; }
      .guideNav_txt {
        margin-top: 9px;
        font-size: 12px; }
    .guide_link {
      margin-top: 23px; }
      .guide_link .btn {
        width: 252px; } }

/*============================================================
  news - 新着情報
  css設定はmodule.scssにて設定
============================================================*/
.news {
  margin-top: 52px; }
  .news_inner {
    margin: 0 auto;
    width: 1300px; }
  .news_list {
    width: 1160px; }
  .news_link {
    width: 1160px; }

@media screen and (max-width: 580px) {
  .news {
    margin-top: 26px; }
    .news_inner {
      width: auto; }
    .news_list {
      width: auto; }
    .news_link {
      width: auto; } }

/*============================================================
  TOPスライダー
----------------------------------
  sliderPro
============================================================*/
.sp-arrows .sp-arrow {
  width: 33px;
  height: 33px;
  background: rgba(76, 76, 76, 0.8); }

.sp-arrows .sp-next-arrow:after, .sp-arrows .sp-next-arrow:before, .sp-arrows .sp-previous-arrow:after, .sp-arrows .sp-previous-arrow:before {
  top: 3px;
  display: inline-block;
  -webkit-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  width: 0;
  height: 0;
  line-height: 1;
  font-size: 26px;
  color: #fff; }

.sp-arrows .sp-previous-arrow:before {
  content: "\f104";
  font-family: FontAwesome;
  left: 50%;
  margin-left: -5px; }

.sp-arrows .sp-next-arrow:before {
  content: "\f105";
  font-family: FontAwesome;
  right: 50%;
  margin-right: 3px; }
